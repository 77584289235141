// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { CognitoIdentityProviderClient, InitiateAuthCommand, InitiateAuthCommandInput } from '@aws-sdk/client-cognito-identity-provider'
import { COGNITO_REGION, COGNITO_CLIENT_ID } from '../../constants/env'

export const cognitoClient = new CognitoIdentityProviderClient({
  region: COGNITO_REGION
})

export const signIn = async (username: string, password: string) => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: COGNITO_CLIENT_ID,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password
    }
  }

  try {
    const command = new InitiateAuthCommand(params)
    const { AuthenticationResult } = await cognitoClient.send(command)
    return AuthenticationResult
  } catch (error) {
    console.error('Error signing in: ', error)
    throw error
  }
}

