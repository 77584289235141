import { PAGES } from '../constants/pages'
import {auth0Client} from "../modules/Auth0/Auth0Client";

export const handleResponse: (response: Response) => Promise<any> = response =>
  handleAnyResponse(response)
    .then(resp => resp.json())

export const handleAnyResponse: (response: Response) => Promise<Response> = response => {
  if (response.status === 401) {
    return Promise.reject(auth0Client.logout(PAGES.login))
  } else if (response.status === 500) {
    throw new Error(`Something went wrong! ${response.status} - ${response.statusText}`)
  } else if (response.status === 503) {
    throw new Error(`App is starting up. Stay put and try again after a couple of minutes!`)
  } else {
    return Promise.resolve(response)
  }
}
