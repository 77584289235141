import * as auth0 from "auth0-js";
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN
} from "../../constants/env";
import { PAGES } from "../../constants/pages";
import { tokenStorage } from "./TokenStorage";

export class Auth0Client {
  auth = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID
  });

  options = {
    domain: AUTH0_DOMAIN,
    redirectUri: window.location.origin + PAGES.callback,
    audience: AUTH0_AUDIENCE,
    responseType: "token",
    scope: "openid profile email"
  };

  logout(redirectTo?: string) {
    tokenStorage.deleteToken();
    // this.auth.logout({ clientID: AUTH0_CLIENT_ID });

    if(redirectTo) {
      window.location.pathname = redirectTo
    }
  }

  parseCallbackResponse() {
    this.auth.parseHash({ hash: window.location.hash }, function(
      err,
      authResult
    ) {
      if (authResult && authResult.accessToken) {
        tokenStorage.saveToken(authResult.accessToken, authResult.expiresIn);
      } else {
        throw new Error(`Auth0 error: ${JSON.stringify(err)}.`);
      }
    });
  }
}

export const auth0Client = new Auth0Client();
