import React from "react";
import { auth0Client } from "./Auth0Client";
import {PAGES} from "../../constants/pages";
import history from "../../utils/History"

export const Callback = () => {
  try {
    auth0Client.parseCallbackResponse();
    history.push(PAGES.explorer);
  } catch (e) {
    console.error("Login error", e);
    history.push(PAGES.login);
  }
  return <></>;
};

export const CallBackContainer = Callback;
